/**
    QUERY
**/
@media only screen and (max-width: 480px) {
    .Home-illustration {
        display: none !important;
        visibility: hidden;
    }
    .Home-appsection-appimg {
        text-align: center;
        position: static;
        margin-top: -200px;
        max-height: 600px;
        max-width: 330px;
    }

    .Home-Mt {
        margin-top: 500px !important;
    }
}
.Home-Mt {
    margin-top: 0px;
}
/**
    MAIN SECTION
**/

.Home-beta-title {
    color: #80e4bc;
    font-weight: 600;
    font-size: 18px;
}

.Home-container {
    margin-left: 10%;
    margin-right: 10%;
}

.Home-intro-container {
    margin-top: 50px;
}

.Home-main-title {
    color: #3e517a;
    font-weight: 800;
    font-size: 38px;
}

.Home-illustration {
    display: initial;
    position: absolute;
    height: 350px;
    z-index: -100;
}

.Home-main-subtitle {
    max-width: 350px;
    margin-top: 40px;
    color: #889096;
    font-weight: 600;
}

/**
    APP SECTION
**/
.Home-appsection-bg {
    margin-top: 200px;
    background-color: #4aa5f7;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Home-appsection-title {
    color: white;
    font-weight: 800;
    font-size: 38px;
}

.Home-appsection-subtitle {
    color: white;
    font-weight: 600;
}

.Home-appsection-item {
    font-style: italic;
    color: white;
}
.Home-appsection-appimg {
    position: absolute;
    margin-top: -100px;
    max-height: 600px;
}

.Home-appsection-download {
    padding: 10px 20px;
    border: 1px solid white;
    border-radius: 50px;
    font-weight: 600;
    font-size: 20px;
    color: white;
}

/**
    WEB SECTION
**/
.Home-txtCenter {
    text-align: center;
}
.Home-websection-spacing {
    margin-top: 100px;
}
.Home-websection-subtitle {
    color: #889096;
    font-weight: 600;
}
.Home-websection-descr {
    color: #3e517a;
    font-weight: 600;
    font-size: 18px;
}

.Home-websection-demo {
    background-color: #4aa5f7;
    border-radius: 50px;
    padding: 8px 20px;
    font-weight: 800;
    color: #fff;
    font-size: 18px;
    margin: 0;
}
.Home-websection-demo:hover {
    background-color: #4aa5f7;
    border-radius: 50px;
    padding: 8px 20px;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    transform: scale(1.05);
    font-weight: 800;
    color: #fff;
    font-size: 18px;
    margin: 0;
}
.Home-websection-menuplayer {
    display: inline;
    color: #889096;
    font-weight: 600;
    cursor: pointer;
    margin-left: 20px;
}
.Home-websection-menuplayer-selected {
    color: #ed5466;
    font-weight: 600;
    border-bottom: 4px solid #ed5466;
    border-radius: 3px;
    display: inline;
    cursor: pointer;
    margin-left: 20px;
}

.Home-teamsection-bg {
    background-color: #282a36;
}
.Home-teamsection-item {
    background-color: #282a36;
    border-radius: 20px;
}
.Home-teamsection-item:hover {
    background-color: #303241;
    border-radius: 20px;
}
.Home-teamsection-item > img {
    height: 70px;
}
.Home-teamsection-item > p {
    color: whitesmoke;
    font-weight: 500;
}

.Home-playersection-bg {
    background-color: #f7f8fa;
}

.Home-footer-bg {
    background-color: #191a21;
}
.Home-footer-content {
    color: whitesmoke;
    font-size: 18px;
    font-weight: 500px;
    margin: 10px 10px;
}
