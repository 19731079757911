.SpotList-title {
    font-weight: 600;
    color: #266cf6;
    font-size: 23px;
}

.SpotList-txtCenter {
    text-align: center;
}

.SpotList-info {
    font-weight: 600;
    color: #6e6e6e;
}
