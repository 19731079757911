.NavBar-Logo {
    font-weight: 800;
    color: #3e517a;
    font-size: 20px;
    margin: 10px 0;
}
.NavBar-style {
    background-color: #ffffff !important;
}
.NavBar-demo {
    background-color: #4aa5f7;
    border-radius: 50px;
    padding: 8px 20px;
}
.NavBar-demo:hover {
    background-color: #4aa5f7;
    border-radius: 50px;
    padding: 8px 20px;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    transform: scale(1.05);
}
.NavBar-demo > p {
    font-weight: 800;
    color: #fff;
    font-size: 18px;
    margin: 0;
}
.NavBar-item {
    font-weight: 800;
    color: #3e517a;
    font-size: 30px;
    margin: 0;
    cursor: pointer;
}
.NavBar-align {
    align-items: center;
    display: flex;
}
.NavBar-item-container {
    margin: 10px 20px;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
    color: inherit;
}
