.SpotListItem-wrapper-online {
    padding: 10px 20px;
    margin-bottom: 12px;
    border: 1px solid #1f5ff6;
    border-radius: 40px;
}

.SpotListItem-wrapper-offline {
    padding: 10px 20px;
    margin-bottom: 12px;
    border: 1px solid #bb2a1f;
    border-radius: 40px;
}

.SpotListItem-dot-online {
    margin-top: 8px;
    margin-left: 15px;
    height: 25px;
    width: 25px;
    background-color: #1f5ff6;
    border-radius: 50%;
    display: inline-block;
}
.SpotListItem-dot-offline {
    margin-top: 8px;
    margin-left: 15px;
    height: 25px;
    width: 25px;
    background-color: #bb2a1f;
    border-radius: 50%;
    display: inline-block;
}

.SpotListItem-name {
    font-weight: 600;
    color: #081c4b;
    margin: 0px;
}

.SpotListItem-left {
    text-align: left;
}

.SpotListItem-song {
    font-weight: 600;
    color: #6e6e6e;
    margin: 0px;
}
